<template>
  <div class="members-directory marketing-phone">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.push({ name: 'MarketingCampaign' })" class="btn customBtn pattern-btn white--text">
            <span> {{ $t("marketingCampaign.back") }}</span>
          </button>
          <v-spacer v-if="$vuetify.breakpoint.width < 500" />
          <h4 class="car-count" v-if="$vuetify.breakpoint.width < 500">
            <span> {{ (marketingContactsSearch.meta && marketingContactsSearch.meta.total) || 0 }}</span>
          </h4>
          <v-btn v-if="$vuetify.breakpoint.width < 500" :disabled="selectedContact.length > 0 ? false : true"
            class="btn customBtn pattern-btn white--text text-capitalize"
            @click="$router.push({ name: 'AddCampaign', params: { contacts: selectedContact } })">
            <span> {{ $t("marketingCampaign.newCampaing") }}</span>
          </v-btn>
        </div>
        <v-spacer />
        <div class="box-top-right">
          <h4 class="car-count" v-if="$vuetify.breakpoint.width > 500">
            <span> {{ (marketingContactsSearch.meta && marketingContactsSearch.meta.total) || 0 }}</span>
          </h4>
          <v-btn v-if="$vuetify.breakpoint.width > 500" :disabled="selectedContact.length > 0 ? false : true"
            class="btn customBtn pattern-btn white--text text-capitalize"
            @click="$router.push({ name: 'AddCampaign', params: { contacts: selectedContact } })">
            <span> {{ $t("marketingCampaign.newCampaing") }}</span>
          </v-btn>
          <v-btn class="btn customBtn pattern-btn white--text text-capitalize" @click="openDialog">
            <span> {{ $t("marketingCampaign.addEmail") }}</span>
          </v-btn>
          <!-- <v-card class="pa-0 d-flex word-search" flat> -->
            <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
              :placeholder="$t('posts.searchForWords')" solo @input="fetchMarketingContacts" append-icon="mdi-magnify">
            </v-text-field>
          <!-- </v-card> -->
        </div>
      </div>

      <ApiErrorMessage :message="apiErrorMessage" />
      <div class="custom-card inside-model-box">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :headers="headers" :items="marketingContactsSearch.data"
            :single-expand="singleExpand" :expanded.sync="expanded" fixed-header :disable-sort="true"
            :show-expand="false" class="color-light-green" :loading="loading" :page.sync="page"
            :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
            <template v-slot:header.id="{ header }">
              <v-checkbox class="pr-0 pt-0 mt-0" style="height: 25px" v-model="selectAll">
              </v-checkbox>
            </template>
            <template v-slot:item.id="{ item }">
              <v-checkbox class="pr-0 pt-0 mt-0" style="height: 25px" v-model="selectedContact" :value="item.id">
              </v-checkbox>
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.tags="{ item }">
              <v-expansion-panels v-model="panel" multiple v-if="item.tags" class="custom-expandable mt-1 mb-1">
                <v-expansion-panel :value="0">

                  <!-- Display the first tag in the expansion panel header -->
                  <v-expansion-panel-header>
                    <span><v-chip>{{ item.tags.split(',')[0].trim() }}</v-chip></span>
                  </v-expansion-panel-header>

                  <!-- Display the remaining tags in the expansion panel content -->
                  <v-expansion-panel-content>
                    <v-chip v-for="(tag, index) in item.tags.split(',').slice(1)" :key="index">
                      {{ tag.trim() }}
                    </v-chip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="action-btn-row">
                <a class="action-btn mr-2" @click="showEditDailog(item)">
                  <v-icon>mdi-pencil</v-icon>
                </a>
                <a class="action-btn" @click="
                  deletedContact = item;
                dialogDelete = true;
                ">
                  <v-icon>mdi-delete</v-icon>
                </a>
              </div>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p>{{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <v-dialog hide-overlay :attach="true" v-model="emailDialog" max-width="600px"
          :content-class="'image-slider rd-custom-modal'" persistent>
          <v-card>
            <v-card-title v-if="!isEmailEdit">
              <h4>{{ $t("marketingCampaign.emailTable.addTitle") }}</h4>
              <button class="close-btn" @click="emailDialog = !emailDialog; isEmailEdit = false;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-card-title v-if="isEmailEdit">
              <h4>{{ $t("marketingCampaign.emailTable.editTitle") }}</h4>
              <button class="close-btn" @click="emailDialog = !emailDialog; isEmailEdit = false;">
                <img src="./../../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-form>
              <div>
                <v-form>
                  <v-card-text>
                    <v-row class="flex-grow-1 overflow-y-auto custom-chat-height">
                      <v-col cols="6" md="6">
                        <v-text-field hide-details="auto" v-model="emailForm.name" @input="$v.emailForm.name.$touch()"
                          @blur="$v.emailForm.name.$touch()" :placeholder="$t('marketingCampaign.emailTable.name')" solo
                          dense :error-messages="nameErrors" :maxlength="200"></v-text-field>
                        <!-- <v-progress-linear
                        indeterminate
                        color="green"
                      ></v-progress-linear> -->
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field hide-details="auto" v-model="emailForm.email" @input="$v.emailForm.email.$touch()"
                          @blur="$v.emailForm.email.$touch()" :placeholder="$t('marketingCampaign.emailTable.email')"
                          solo dense :error-messages="emailErrors"></v-text-field>

                      </v-col>
                      <v-col cols="12" md="12">
                        <v-autocomplete height="45" v-model="emailForm.tags" :items="tagsArrayList"
                          :search-input.sync="search" :label="$t('marketingCampaign.tagsLabel')"
                          @change="(event) => onSelectTags(event)" :value="emailForm.tags" :error-messages="tagsErrors"
                          solo dense clearable multiple deletable-chips small-chips hide-selected hide-no-data
                          hide-details :menu-props="{
                            closeOnContentClick: true,
                          }">
                        </v-autocomplete>
                        <span class="note-text">{{ $t('marketingCampaign.tags') }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btn customBtn red-fill-btn pattern-btn white--text text-capitalize font-16"
                      @click="emailDialog = !emailDialog; isEmailEdit = false;">
                      <span>{{ $t("marketingCampaign.close") }}</span>
                    </v-btn>
                    <v-btn class="btn customBtn pattern-btn text-capitalize ml-2 white--text font-16" :loading="saving"
                      @click="saveContact" :disabled="!emailForm.name || !emailForm.email">
                      <span>{{ !isEmailEdit ? $t("marketingCampaign.addEmail") : $t("marketingCampaign.updateEmail")
                        }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </div>
            </v-form>
          </v-card>
        </v-dialog>
        <confirmation-dialog @close="dialogDelete = false" @confirm="deleteContactConfirm" :dialog="dialogDelete"
          :dialogHeadline="$t('marketingCampaign.deleteHeadline')"></confirmation-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";
import ConfirmationDialog from "../common/ConfirmationDialog";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
    ConfirmationDialog
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",


      panel: [0],
      loading: false,
      emailDialog: false,
      isEmailEdit: false,
      page: 1,
      searchText: "",
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      saving: false,
      dialogDelete: false,
      deletedContact: {},
      emailForm: {
        id: null,
        name: null,
        number: null,
        email: null,
        tags: [],
        type: true,
      },
      tagsArrayList: [],
      queryTerm: null,
      selectedContact: [],
      errors: {
        name: null,
        email: null,
        tags: null
      }
    };
  },
  validations: {
    emailForm: {
      name: { required },
      email: { required, email },
      // tags: { required, minLength: minLength(1) },
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      marketingContactsSearch: "marketingCampaign/getMarketingContactsSearch",
      tagsSearch: "marketingCampaign/getTagsSearch",
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.emailForm.name.$dirty) return errors;
      !this.$v.emailForm.name.required &&
        errors.push(this.$t("marketingCampaign.validations.nameIsRequired"));
      if (this.errors && this.errors.name) {
        return this.errors.name;
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.emailForm.email.$dirty) return errors;
      !this.$v.emailForm.email.required &&
        errors.push(this.$t("marketingCampaign.validations.emailIsRequired"));
      !this.$v.emailForm.email.email &&
        errors.push(this.$t("marketingCampaign.validations.emailIsInvalid"));
      if (this.errors && this.errors.email) {
        return this.errors.email;
      }
      return errors;
    },
    tagsErrors() {
      // const errors = [];
      // if (!this.$v.emailForm.tags.$dirty) return errors;
      // !this.$v.emailForm.tags.required &&
      //   errors.push(this.$t("marketingCampaign.validations.tagsIsRequired"));
      // !this.$v.emailForm.tags.minLength &&
      //   errors.push(this.$t("marketingCampaign.validations.tagsMinLength"));
      // return errors;
      if (this.errors && this.errors.tags) {
        return this.errors.tags;
      }
    },
    headers() {
      return [
        {
          text: 'checkbox',
          align: "left",
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("marketingCampaign.emailTable.email"),
          align: "left",
          value: "email",
          width: "20%",
        },
        {
          text: this.$t("marketingCampaign.emailTable.name"),
          align: "left",
          value: "name",
          width: "23%",
        },
        {
          text: this.$t("marketingCampaign.emailTable.tags"),
          align: "left",
          value: "tags",
          width: "32%",
        },
        {
          text: this.$t("marketingCampaign.emailTable.action"),
          align: "left",
          value: "action",
          width: "20%",
        },
      ];
    },
    search: {
      get() {
        return this.queryTerm
      },
      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.fetchTags();
        }
      }
    },
    selectAll: {
      get: function () {
        return this.marketingContactsSearch.data ? this.selectedContact.length == this.marketingContactsSearch.data.length : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.marketingContactsSearch.data.forEach(function (contact) {
            selected.push(contact.id);
          });
        }
        this.selectedContact = selected;
      }
    }
  },
  async mounted() {
    await this.fetchMarketingContacts();
  },
  methods: {
    ...mapActions({
      getMarketingContacts: "marketingCampaign/getMarketingContacts",
      saveMarketingContacts: "marketingCampaign/saveMarketingContacts",
      updateMarketingContacts: "marketingCampaign/updateMarketingContacts",
      deleteMarketingContacts: "marketingCampaign/deleteMarketingContacts",
      getTags: "marketingCampaign/getTags",
    }),


    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchMarketingContacts(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchMarketingContacts(); // Fetch new data based on the current page
      }
    },

    async fetchMarketingContacts() {
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        await this.getMarketingContacts({
          search: this.searchText,
          page: this.currentPage,
          items_per_page: this.perPage,
          type: this.emailForm.type,
        });

        if (this.marketingContactsSearch.meta) {
          this.totalEntries = this.marketingContactsSearch.meta.total;
          this.itemsPerPage = parseInt(this.marketingContactsSearch.meta.per_page);
          this.pageCount = this.marketingContactsSearch.meta.last_page;
        } else {
          this.itemsPerPage = this.marketingContactsSearch.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async fetchTags() {
      this.loading = true;
      try {
        if (this.queryTerm != null) {
          await this.getTags({
            search: this.queryTerm
          });
        }
        this.tagsArrayList = this.tagsSearch;
        this.tagsArrayList = this.tagsArrayList.concat(this.emailForm.tags);
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    onSelectTags(value) {
      this.queryTerm = null;
      this.tagsArrayList = [].concat(this.emailForm.tags);
    },
    async saveContact() {
      // this.$v.emailForm.$touch();
      // if (!this.$v.emailForm.$invalid) {
      try {
        this.saving = true;
        var data = _.cloneDeep(this.emailForm);
        data.tags = data.tags.join(', ');
        if (this.isEmailEdit && this.emailForm.id) {
          await this.updateMarketingContacts(data);
        } else {
          await this.saveMarketingContacts(data);
        }
        this.resetFormData();
        this.emailDialog = false;
        this.isEmailEdit = false;
      } catch ({ message }) {
        this.errors.name = message.name;
        this.errors.email = message.email;
        this.errors.tags = message.tags;
        // this.apiErrorMessage = message;
      } finally {
        this.saving = false;
      }
      // }
    },
    async deleteContactConfirm() {
      this.$store.commit("setLoader", true);
      try {
        let data = {
          id: this.deletedContact.id,
          type: this.emailForm.type
        }
        await this.deleteMarketingContacts(data);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
      } finally {
        this.$store.commit("setLoader", false);
      }
      this.dialogDelete = false;
    },
    openDialog() {
      this.emailDialog = true;
      this.resetFormData();
    },
    showEditDailog(item) {
      this.isEmailEdit = true;
      this.resetFormData();
      this.emailForm.id = item.id;
      this.emailForm.name = item.name;
      this.emailForm.email = item.email;
      this.emailForm.tags = item.tags ? item.tags.split(', ') : [];
      this.tagsArrayList = this.emailForm.tags;
      this.emailDialog = true;
    },
    resetFormData() {
      this.tagsArrayList = [];
      this.emailForm = {
        name: null,
        number: null,
        email: null,
        tags: [],
        type: true,
      }
      this.$v.$reset()
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>